import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import makeStyles from '@mui/styles/makeStyles';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { i18n } from "../../translate/i18n";
import QueueSelect from "../QueueSelect";

import InputVariables from "../../components/InputVariables";
import useTenant from "../../hooks/useTenant";
import BotSelect from "../BotSelect";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import GPTModalButton from "../GPTModalButton";

const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    btnWrapper: {
        position: "relative",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    labelPaddingInput: {
        marginTop: "-3px",
        "& input": {
            textAlign: "center"
        }
    },
    multFieldLineLeft: {
        display: "flex",
        justifyContent: "flex-start",
        // "& > *:not(:last-child)": {
        "& > *:last-child": {
            // marginRight: theme.spacing(1),
            marginRight: 0,
        },
        width: "100%",
    },
    removeBottomMargin: {
        marginBottom: 0,
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: 16
    }
}));

const SessionSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles();
    const initialState = {
        botId: "",
        checkGroups: false,
        checkImport: false,
        checkImportGroups: false,
        enableBot: false,
        farewellMessage: "",
        getHistory: '',
        greetingMessage: "",
        isDefault: false,
        name: "",
        acceptAudio: true,
        acceptCall: false,
        sendMenuFirstInteraction:false
    };
    const [whatsApp, setWhatsApp] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);

    const { tenantId } = useTenant();

    const [field, setField] = useState();

    const axiosPrivate = useAxiosPrivate();

    const handleFocus = (e) => {
        setField(e.target);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSession = async () => {
            if (!whatsAppId || !tenantId) return;

            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/whatsapp/${whatsAppId}`);
                if (data?.botId === null) data.botId = "";
                if (data && isMounted) {
                    setWhatsApp(data);
                    const whatsQueueIds = data?.queues?.map(queue => queue.id);
                    whatsQueueIds && setSelectedQueueIds(whatsQueueIds);
                }

            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, whatsAppId]);

    const handleSaveWhatsApp = async values => {
        const whatsappData = {
            ...values,
            botId: values.botId === ""
                ? null
                : values.botId,
            queueIds: selectedQueueIds
        };
        if (tenantId) {
            try {
                if (whatsAppId) {
                    await axiosPrivate.put(`/${tenantId}/whatsapp/${whatsAppId}`, whatsappData);
                } else {
                    await axiosPrivate.post(`/${tenantId}/whatsapp`, whatsappData);
                }
                toast.success(i18n.t("translation.whatsappModal.success"));
                handleClose();
            } catch (err) {
                toastError(err);
            }
        };
    };

    const handleClose = () => {
        onClose();
        setWhatsApp(initialState);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    {whatsAppId
                        ? i18n.t("translation.whatsappModal.title.edit")
                        : i18n.t("translation.whatsappModal.title.add")}
                </DialogTitle>
                <Formik
                    initialValues={whatsApp}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWhatsApp(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting, setFieldValue, getFieldProps }) => (
                        <Form>
                            <DialogContent dividers>
                                <MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.whatsappModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={classes.textField}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                color="primary"
                                                name="isDefault"
                                                checked={values.isDefault}
                                            />
                                        }
                                        label={i18n.t("translation.whatsappModal.form.default")}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                color="primary"
                                                name="enableBot"
                                                checked={values.enableBot}
                                            />
                                        }
                                        label={i18n.t("translation.whatsappModal.form.enableBot")}
                                    />

                                </MultiFieldLine>
                                <Box sx={{ display: values.enableBot === false ? "none" : "block" }} >
                                    <BotSelect
                                        current=""
                                        fieldId="botId"
                                    />
                                </Box>
                                <Box sx={{ display: values.enableBot === true ? "none" : "block" }} >
                                    <GPTModalButton
                                        disabled={isSubmitting}
                                        field={field || getFieldProps("greetingMessage")}
                                        setFieldValue={setFieldValue}
                                        prompt={i18n.t("translation.gptModal.prompts.greetingMessage")}
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.queueModal.form.greetingMessage")}
                                        type="greetingMessage"
                                        multiline
                                        minRows={5}
                                        fullWidth
                                        name="greetingMessage"
                                        onFocus={handleFocus}
                                        error={
                                            touched.greetingMessage && Boolean(errors.greetingMessage)
                                        }
                                        helperText={
                                            touched.greetingMessage && errors.greetingMessage
                                        }
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                                <div>
                                    <GPTModalButton
                                        disabled={isSubmitting}
                                        field={field || getFieldProps("farewellMessage")}
                                        setFieldValue={setFieldValue}
                                        prompt={i18n.t("translation.gptModal.prompts.farewellMessage")}
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.whatsappModal.form.farewellMessage")}
                                        type="farewellMessage"
                                        multiline
                                        minRows={5}
                                        fullWidth
                                        name="farewellMessage"
                                        onFocus={handleFocus}
                                        error={
                                            touched.farewellMessage && Boolean(errors.farewellMessage)
                                        }
                                        helperText={
                                            touched.farewellMessage && errors.farewellMessage
                                        }
                                        variant="outlined"
                                        size="small"
                                    />
                                </div>
                                <InputVariables group="connections" field={field} setFieldValue={setFieldValue} />
                                <Box sx={{ display: values.enableBot === true ? "none" : "block" }}>
                                    <QueueSelect
                                        selectedQueueIds={selectedQueueIds}
                                        onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                                    />
                                </Box>

                                <Stack mb={2}>
                                    <Accordion
                                        className={classes.accordion}
                                        expanded={true}
                                        disableGutters
                                    >
                                        <AccordionSummary>
                                            <FormControl component="fieldset" className={classes.flexColumn}>
                                                <Tooltip arrow title={i18n.t("translation.settings.settings.audio.note")}>
                                                    <FormGroup >
                                                        <Field
                                                            as={FormControlLabel}
                                                            name="acceptAudio"
                                                            control={<Checkbox size="small" checked={values.acceptAudio} />}
                                                            label={i18n.t("translation.whatsappModal.form.acceptAudio")}
                                                            labelPlacement="end"
                                                            className={classes.removeBottomMargin}
                                                        />
                                                    </FormGroup>
                                                </Tooltip>
                                                <Tooltip arrow title={i18n.t("translation.settings.settings.call.note")}>
                                                    <FormGroup >
                                                        <Field
                                                            as={FormControlLabel}
                                                            name="acceptCall"
                                                            control={<Checkbox size="small" checked={values.acceptCall} />}
                                                            label={i18n.t("translation.settings.settings.call.name")}
                                                            labelPlacement="end"
                                                            className={classes.removeBottomMargin}
                                                        />
                                                    </FormGroup>
                                                </Tooltip>
                                                <Tooltip arrow title={i18n.t("translation.whatsappModal.tooltips.sendMenuFirstInteraction")}>
                                                    <FormGroup >
                                                        <Field
                                                            as={FormControlLabel}
                                                            name="sendMenuFirstInteraction"
                                                            control={<Checkbox size="small" checked={values.sendMenuFirstInteraction} />}
                                                            label={i18n.t("translation.whatsappModal.form.send.sendMenuFirstInteraction")}
                                                            labelPlacement="end"
                                                            className={classes.removeBottomMargin}
                                                        />
                                                    </FormGroup>
                                                </Tooltip>
                                                <Tooltip arrow title={i18n.t("translation.whatsappModal.tooltips.checkGroups")}>
                                                    <FormGroup >
                                                        <Field
                                                            as={FormControlLabel}
                                                            name="checkGroups"
                                                            control={<Checkbox size="small" checked={values.checkGroups} />}
                                                            label={i18n.t("translation.whatsappModal.form.import.checkGroups")}
                                                            labelPlacement="end"
                                                            className={classes.removeBottomMargin}
                                                        />
                                                    </FormGroup>
                                                </Tooltip>
                                            </FormControl>
                                        </AccordionSummary>
                                    </Accordion>
                                </Stack>
                                <Stack>
                                    <Accordion
                                        className={classes.accordion}
                                        expanded={values.checkImport}
                                        disableGutters
                                    >
                                        <AccordionSummary>
                                            <Tooltip arrow title={i18n.t("translation.whatsappModal.tooltips.checkImport")}>
                                                <FormControl component="fieldset">
                                                    <FormGroup >
                                                        <Field
                                                            as={FormControlLabel}
                                                            name="checkImport"
                                                            control={<Checkbox size="small" checked={values.checkImport} />}
                                                            label={i18n.t("translation.whatsappModal.form.import.checkImport")}
                                                            labelPlacement="end"
                                                            className={classes.removeBottomMargin}
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Tooltip>
                                        </AccordionSummary>

                                        <AccordionDetails className={classes.flexWrap}>
                                            <MultiFieldLine left={true}>
                                                <Typography>
                                                    {i18n.t("translation.whatsappModal.form.import.getHistory")}
                                                </Typography>
                                                <Field
                                                    as={TextField}
                                                    name="getHistory"
                                                    type="number"
                                                    variant="standard"
                                                    inputProps={{ min: 0, max: 1440 }}
                                                    style={{ width: 50 }}
                                                    className={classes.labelPaddingInput}
                                                />
                                                <Typography>
                                                    {i18n.t("translation.whatsappModal.form.import.days")}
                                                </Typography>
                                            </MultiFieldLine>

                                            <Field
                                                as={FormControlLabel}
                                                name="checkImportGroups"
                                                control={<Checkbox size="small" checked={values.checkImportGroups} />}
                                                label={i18n.t("translation.whatsappModal.form.import.checkImportGroups")}
                                                labelPlacement="end"
                                                className={classes.removeBottomMargin}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Stack>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {whatsAppId
                                        ? i18n.t("translation.validation.buttons.okEdit")
                                        : i18n.t("translation.validation.buttons.okAdd")}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
};

export default React.memo(WhatsAppModal);
